.footer {
    max-width: 600px;
    min-width: 280px;
    width: 100%;
    height: 65px;
    box-shadow: 0 -3px 6px 0 rgba(0, 0, 0, 0.16);
    background-color: #fff;
    display: flex;
    justify-content: space-around;
    position: absolute;
    bottom: 0;
}

.footer-img {
    margin-top: 10px;
}