@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
h1 {
    margin: 0;
    padding: 0;
}

.popup {
    position: fixed;
    max-width: 600px;
    min-width: 280px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9;
}

.popup_inner {
    width: 232px;
    height: 252px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    margin: auto;
    background: white;
    /* border: solid 1px rgb(97, 97, 97); */
    border-radius: 6px;
    box-shadow: -0.5px -0.5px 4px 1px rgb(129 130 131 / 64%);
}

.popup_inner_short {
    width: 232px;
    height: 115px;
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    margin: auto;
    background: white;
    /* border: solid 1px rgb(97, 97, 97); */
    border-radius: 6px;
    box-shadow: -0.5px -0.5px 4px 1px rgb(129 130 131 / 64%);
}

.popup_inner_bottom {
    height: 40px;
    line-height: 40px;
    font-weight: 600;
    color: #ffffff;
}

.popup_inner_bottom div:first-child {
    border-bottom-left-radius: 6px;
    background-color: lightgray;
}

.popup_inner_bottom div:last-child {
    border-bottom-right-radius: 6px;
    background-color: #D01A6F;
}

.popup_warning {
    width: 232px;
    height: 148px;
    max-width: 260px;
    margin: auto;
    position: absolute;
    overflow: hidden;
    background: white;
    border-radius: 10px;
    animation: vibrate 0.05s linear 0s reverse;
    -moz-animation: vibrate 0.05s linear 0s reverse;
    /* Firefox */
    -webkit-animation: vibrate 0.05s linear 0s reverse;
    /* Safari and Chrome */
    -o-animation: vibrate 0.05s linear 0s reverse;
    /* Opera */
}

.popup_qna {
    width: 100%;
    height: 100%;
    max-width: 500px;
    margin: auto;
    position: relative;
    /* position: absolute;
    top: 0;
    bottom: 0; */
    /* border: solid 1px rgb(196, 195, 195); */
    /* background: white; */
    /* border-top: solid 20px; */
    color: #fff;
}

.popup_qna h2 {
    font-size: 1.1rem;
    height: 25px;
}
.qna_cont {
    position: relative;
    left: 5px;
}
.qna_cont p {
    position: relative;
    left: 10px;
    line-height: 20px;
}

.popup_custom_top {
    width: 100%;
    margin: 0 auto;
    padding: 5px 20px;
}

.popup_custom_top>span {
    font-size: 1.15rem;
    font-weight: 700;
    line-height: 1.33;
    letter-spacing: -0.18px;
    text-align: center;
    color: #494949;
}

.popup_custom_top>div>h2,
.popup_custom_top>div>h3 {
    font-weight: bold;
}

.popup_custom_center {
    width: 100%;
    margin: 0 auto;
    padding: 0 20px;
}

.popup_custom_link {
    width: 100%;
    margin: 0 auto;
    position: absolute;
    bottom: 90px;
}

.popup_custom_link>a {
    color: black;
    font-size: 11px;
    font-weight: 500;
    vertical-align: bottom;
    text-align: center;
}

.popup_custom_link>a:active,
.popup_custom_link>a:hover {
    color: black;
}

.popup_custom_bottom {
    width: 100%;
    max-width: 500px;
    max-height: 70px;
    position: absolute;
    font-size: .875rem;
    bottom: 0;
    line-height: 5;
    text-align: center;
    color: #ffffff;
    background-color: #CD0572;
}

.popup_custom_info {
    width: 100%;
    max-width: 280px;
    color: black;
    position: absolute;
    bottom: 0;
    background-color: #ffffff;
}

.popup_info_btn {
    line-height: 4;
    text-align: center;
    color: #ffffff;
    background-color: #CD0572;
}

.popup_find {
    width: 100%;
    width: 232px;
    max-width: 260px;
    position: absolute;
    margin: auto;
    overflow: hidden;
    background: white;
    border-radius: 10px;
    box-shadow: -0.5px -0.5px 4px 1px rgb(129 130 131 / 64%);
}

.popup_info {
    width: 100%;
    height: 100%;
    max-width: 280px;
    margin: auto;
    background: white;
    border-top: solid 20px;
}

.popup_info_content>div {
    padding: 5px 20px;
    line-height: 2;
    border-top: solid 2px lightgray;
}

.popup_info_content>div:nth-child(1) {
    border-top: none;
}

.popup_custom_center>div {
    /* font-family: "NotoSansCJKKR"; */
    font-size: 11px;
    font-weight: 500;
    color: #383838;
}

.info_sub_content {
    max-height: 210px;
    overflow-y: scroll;
    text-align: left;
    font-size: 10px;
    line-height: 1.6;
}

.info_sub_content>p:first-child {
    padding-top: .5rem;
}

.info_sub_content>p:nth-child(2) {
    padding-top: .5rem;
    padding-bottom: .5rem;
}

.info_sub_content>p:last-child {
    padding-bottom: .5rem;
}

.privacy_check {
    border-radius: 10px !important;
}

.privacy_check:checked {
    background-color: #CD0572 !important;
    border-color: #CD0572 !important;
}

.popup_warning.privacy {
    z-index: 999;
}

.find_list>div {
    width: 80%;
    margin: 0 auto;
    line-height: 3;
    border-radius: 4px;
    border: solid 1px lightgrey;
    text-align: center;
    font-size: 13px;
    letter-spacing: 0.25px;
}

.find_list>div>a {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.71;
    text-align: center;
    color: #494949;
    text-decoration: none;
}

.find_btn {
    width: 100%;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    line-height: 3;
    text-align: center;
    color: #ffffff;
    background-color: #CD0572;
}

.popup_delBtn {
    font-size: 20px;
    cursor: pointer;
    color: #a0a0a0ec;
}

.popup_hr {
    margin: .5rem 0;
    border: solid 1px #c4c4c4 !important;
}

.popup_text {
    width: 100%;
    /* font-family: "Noto Sans Korean", sans-serif; */
    font-size: .875rem;
    font-weight: 600;
    color: #494949;
    margin-top: 20px;
}

.popup_text p {
    padding-bottom: 9px;
}

.popup_btn.warning {
    width: 232px;
    height: 40px;
    line-height: 40px;
    font-size: .875rem;
    font-weight: 700;
    text-align: center;
    background-color: #f3f3f3;
}

.find_pwd {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #383838;
    text-decoration-line: underline;
}

.find_title {
    font-size: 1.25rem;
    font-weight: bold;
    text-align: left;
    color: #383838;
}

.find_subtitle {
    font-size: 11px;
    font-weight: 500;
    text-align: left;
    color: #c4c4c4;
}

.find_count {
    font-size: 11px;
    font-weight: 500;
    text-align: right;
    color: #c4c4c4;
}

.popup_custom_center>div>label {
    font-size: 14px;
    font-weight: 500;
    text-align: left;
    color: #383838;
}

.popup_custom_center>div>input {
    font-size: 14px;
    text-align: left;
    color: #2d2d2d;
    line-height: 2.5 !important;
}

.find_result {
    font-size: 14px !important;
    font-weight: 500;
    color: #383838;
}

.find_result>div {
    font-size: 11px !important;
    color: #c4c4c4;
}

.find_content {
    font-size: 14px !important;
    font-weight: 500;
    color: #383838;
}

.privacy-btn>button:first-child {
    margin-right: 0.5rem;
}

.privacy-btn>button:last-child {
    margin-left: 0.5rem;
}

.result-btn>button:first-child {
    margin-right: 0.5rem;
}

.result-btn>button:last-child {
    margin-left: 0.5rem;
    color: rgb(67, 67, 67);
}

@keyframes vibrate {
    0% {
        margin-left: 0px;
    }
    25% {
        margin-left: -6px;
    }
    50% {
        margin-left: 0px;
    }
    75% {
        margin-left: 6px;
    }
    100% {
        margin-left: 0px;
    }
}


/* Firefox */

@-moz-keyframes vibrate {
    0% {
        margin-left: 0px;
    }
    25% {
        margin-left: -6px;
    }
    50% {
        margin-left: 0px;
    }
    75% {
        margin-left: 6px;
    }
    100% {
        margin-left: 0px;
    }
}


/* Safari and Chrome */

@-webkit-keyframes vibrate {
    0% {
        margin-left: 0px;
    }
    25% {
        margin-left: -6px;
    }
    50% {
        margin-left: 0px;
    }
    75% {
        margin-left: 6px;
    }
    100% {
        margin-left: 0px;
    }
}


/* Opera */

@-o-keyframes vibrate {
    0% {
        margin-left: 0px;
    }
    25% {
        margin-left: -6px;
    }
    50% {
        margin-left: 0px;
    }
    75% {
        margin-left: 6px;
    }
    100% {
        margin-left: 0px;
    }
}


/* 통화내역 상세 레이어 팝업 */

.callView_popup_inner {
    height: 100%;
}