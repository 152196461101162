ListGroup.Item {
    color: white;
}

.fs-10 {
    font-size: 10px;
}

.fs-12 {
    font-size: 12px;
}

.fs-20 {
    font-size: 20px;
}

.goupbtn {
    top: 90%;
    left: 80%;
    width: 35px;
    height: 35px;
    font-size: 1rem;
    border-radius: 80%;
    background-color: #ffffff;
    border: #6C757D 3px solid;
    position: fixed;
    z-index: 2;
}

.searchForm {
    margin-top: 14px;
    padding: 0 4px;
    position: relative;
}

.input-search {
    border-radius: 5px;
    border: solid 1px #c4c4c4;
    background-color: #fff;
}

.input-search-icon {
    position: absolute;
    top: 7px;
    right: 10px;
}

.callListOpen {
    display: block;
}

.callListClose {
    display: none !important;
}

.callListDateTitle {
    display: flex;
    align-items: center;
}

.callListDateImg {
    vertical-align: middle
}

.callListDate {
    display: inline-block
}

.callListDataHeader {}

.callTypeLabel {
    width: calc(100%/4);
    text-align: center;
}
.callTypeLabel label {
    width: 70%;
    height: 25px;
    line-height: 23px;
    font-size: 13px;
    font-weight: 500;
    color: #ced4da;
    background: #fff;
    border: 1px solid #ced4da;
    border-radius: 10px;
    cursor: pointer;
}
.callTypeLabel input:checked+label {
    font-weight: 600;
    color: #383838;
    background: #ffebf5;
    /* border: 1px solid #ffebf5; */
}

.blind {
    position: absolute;
    width: 1px;
    height: 1px;
    clip: rect(0 0 0 0);
    overflow: hidden;
}