.title-top {
    color: #cf1a70;
    font-size: 24px;
    font-weight: 700;
    padding-bottom: 6px;
}

.title-bottom {
    color: #383838;
    font-size: 20px;
    font-weight: 700;
    padding-bottom: 42px;
}

.content-card {
    width: 100%;
    height: 350px;
    border-radius: 17px !important;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
}

.content-cardBody {
    padding: 20px 15px 0 16px;
}

.mainTable {
    width: 100%;
    font-size: 14px;
    margin-top: 16px;
}

.mainTable tr {
    height: 40px;
}

.mainTable tr td:first-child {
    width: 70px;
}

.mainTable tr td {
    padding-bottom: 18px;
}

.mainTable tr td:last-child {
    text-align: right;
    font-weight: 500;
}


/* 일반 사용자, 관리자 버튼 */

.btn_auth {
    width: 90px;
    height: 28px;
    border-radius: 8px;
    background-color: #ffebf5;
    font-weight: 600;
    font-size: 14px;
    color: #383838
}


/* 팝업 내부 div  */

.popup_inner_div {
    height: 188px;
}

.popup_inner_div p:first-child {
    margin-top: 24px;
    font-size: 18px;
    color: #494949;
    font-weight: 700;
}

.popup_inner_div p:last-child {
    margin-top: 10px;
    font-size: 13px;
}

.d-inline-block {
    width: 210px;
    height: 40px;
    margin-top: 10px;
    border-radius: 5px;
}

.d-inline-block p {
    font-size: 11px;
    color: #c7c7c7;
    margin-top: 8px;
}

.fc-red {
    color: red;
}