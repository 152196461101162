code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

.test {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #282c34;
}

.section {
    width: 100%;
    height: 100vh;
}

.box {
    display: flex;
    justify-content: center;
    align-items: center;
    background: cornflowerblue;
    color: rgb(32, 36, 41);
    font-weight: 900;
    font-size: 18px;
    width: 300px;
    height: 250px;
    border-radius: 5px;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.07), 0 2px 4px rgba(0, 0, 0, 0.07), 0 4px 8px rgba(0, 0, 0, 0.07), 0 8px 16px rgba(0, 0, 0, 0.07), 0 16px 32px rgba(0, 0, 0, 0.07), 0 32px 64px rgba(0, 0, 0, 0.07);
}

.box img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.isVisible {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    width: 100%;
    background: cornflowerblue;
    color: rgb(17, 16, 20);
    font-size: 20px;
    font-weight: 900;
    padding: 40px;
}

.overheight {
    height: calc((var(--vh, 1vh) * 100) - 270px);
}

.wrapheight {
    height: calc((var(--vh, 1vh) * 100) - 190px);
}