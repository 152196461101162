.bg {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9;
}

.icon_style {
    margin: 0 10px 3px 0;
    font-size: 1.2rem;
    color: white;
}

.topBar {
    height: 24px;
    background-color: black;
}

.sidenav {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 99;
    top: 24px;
    right: 0;
    color: white;
    background-color: white;
    overflow-x: hidden;
    transition: 0.2s;
    /* padding-top: 60px; */
}

.sidenav a {
    padding: 0 21px 0px 0px;
    text-decoration: none;
    font-size: 18px;
    color: black;
    display: block;
    transition: 0.1s;
}


/*
.sidenav a:hover {
    color: black;
    text-decoration: underline;
}
*/

.sidenav .closebtn {
    padding-right: 18px;
    font-size: 24px;
}

.sidenavTop {
    width: 230px;
    height: 172px;
    color: white;
    background-color: #D01A6F;
}

@media screen and (max-height: 450px) {
    /* .sidenav {
        padding-top: 15px;
    } */
    .sidenav a {
        font-size: 18px;
    }
}

.sidenavBottom {
    padding: 30px 0 0 26px;
}

.sidenavBottom a {
    font-size: 17px;
    color: #383838;
    font-weight: 700;
    padding: 0 0 20px 0;
}

.sidenavBottom a:hover {
    color: #383838
}