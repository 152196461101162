@import url(//fonts.googleapis.com/earlyaccess/notosanskr.css);
@import url(https://cdn.jsdelivr.net/gh/moonspam/NanumSquare@1.0/nanumsquare.css);
@import url(https://cdn.jsdelivr.net/gh/orioncactus/pretendard/dist/web/static/pretendard.css);
html,
body {
    width: 100%;
    height: 100%;
    max-width: 600px;
    min-width: 280px;
    /* height: 100vh; */
    margin: auto !important;
    padding: 0;
    background-image: linear-gradient(#ffffff, #f3e6eb);
    background-repeat: no-repeat;
    background-position: 100% 0, 0 0;
    color: #383838;
    font-family: "Pretendard", sans-serif !important;
}

.wrapper {
    width: 100%;
    height: 100%;
    /* height: calc(100%-90px); */
    color: #383838;
    /* background-image: linear-gradient(#ffffff, #f3e6eb);
    background-repeat: no-repeat;
    background-position: 100% 0, 0 0; */
    /* padding-bottom: 170px; */
}