.container-signin {
    width: 100%;
    height: 100vh;
    padding: 0 !important;
}

.form-main.signin {
    width: 100%;
    /* height: 100%; */
    height: calc(100% - 24px);
    margin: 0 auto;
    border: solid 1px #00000054;
    padding: 1rem 22px;
    background: #ffffff;
    background-image: url(/src/img/ping_circle.png), linear-gradient(#ffffff, #fff1f7);
    background-repeat: no-repeat;
    background-position: 110% -80px, 0 0;
}

form.signin {
    margin-top: 50px;
}

.form-signin-heading {
    font-family: 'NanumSquare', sans-serif;
    font-weight: bold;
    margin-top: 60px;
    margin-bottom: 35px;
    color: #cf1a70;
    font-size: 24px;
    font-weight: 800;
    text-align: left;
}

.input-lg.signin {
    width: calc(100% - 2px);
    height: 40px;
    line-height: 1.8;
    font-size: 1rem;
    border: 2px solid #c4c4c4;
}

.input-lg.signin::-webkit-input-placeholder {
    color: #c4c4c4;
    font-size: .875rem;
    font-weight: 600;
}

.input-lg.signin::-moz-placeholder {
    color: #c4c4c4;
    font-size: .875rem;
    font-weight: 600;
}

.input-lg.signin:-ms-input-placeholder {
    color: #c4c4c4;
    font-size: .875rem;
    font-weight: 600;
}

.input-lg.signin:-moz-placeholder {
    color: #c4c4c4;
    font-size: .875rem;
    font-weight: 600;
}

.input-lg.signin::placeholder {
    color: #c4c4c4;
    font-size: .875rem;
    font-weight: 600;
}

.input-lg.signin:focus {
    border: 2px solid #cf1a70 !important;
}

.form-group.signin {
    position: relative;
}

.input-signin_icon {
    position: absolute;
    top: 12px;
    right: 13px;
    color: #02020274;
}

.form_rember {
    width: 18px !important;
    height: 18px !important;
    font-size: 15px !important;
    padding: 0px !important;
}

.form_rember:checked {
    background-color: #CD0572 !important;
    border-color: #CD0572 !important;
}

.form-signin>label {
    font-size: 0.8rem;
    font-weight: 500;
}

.form-signin-label {
    padding-left: 0.4rem;
    font-size: 0.765rem;
    line-height: 2.9;
}

.btn-lg.signin {
    width: 100%;
    height: 40px;
    margin: 0 auto;
    margin-top: 10px !important;
    font-size: 0.85rem !important;
    font-weight: 700;
    text-align: center !important;
    border: #cf1a70;
    background-color: #cf1a70;
}

.btn-lg.signin:active,
.btn-lg.signin:hover,
.btn-lg.signin:focus {
    border: #cf1a70;
    background-color: #cf1a70;
}

.btn-lg.signin:active,
.btn-lg.signin:hover,
.btn-lg.signin:focus {
    border: #CD0572;
    background-color: #CD0572;
}

.service_list {
    margin: 15px auto;
    padding: 0 32px;
}

.service_list div:first-child {
    margin-right: 16px;
}

.form-signin-p {
    font-size: .688rem;
    font-weight: 600;
    color: #515151;
    text-decoration: none;
}

.form-signin-qna {
    font-size: 0.7rem;
    line-height: 2.5;
    color: #cf1a70;
}

.form-signin-qna>span {
    color: #cf1a70;
    font-size: 0.765rem;
    font-weight: 700;
    text-decoration-line: underline;
}

.input_error {
    border: solid 1px #cf1a70 !important;
}

.input_error::placeholder {
    color: #cf1a70 !important;
}


/* FindInfo css */

.find_tab>li>button {
    margin: auto;
    font-size: 0.2rem;
    color: black;
}

.find_tab>li>.nav-link.active {
    color: #0066ff !important;
}

.form-signup>div {
    font-size: 0.75rem;
}