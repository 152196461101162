@import 'react-calendar/dist/Calendar.css';

/* Calendar */

.react-calendar {
    height: 213px;
    border: 0px !important;
    padding: 0 20px 0 15px;
    margin-bottom: 5px;
}

.react-calendar__navigation {
    height: 20px !important;
    margin: 0 !important;
}

.react-calendar__month-view__days button {
    height: 25px;
    font-size: .6875rem;
}

.react-calendar__navigation__label {
    width: 100px;
    height: 28px;
    font-size: 12px;
    border-radius: 3px;
    background-color: #f7f7f7;
}

.react-calendar__navigation__prev-button,
.react-calendar__navigation__next-button {
    font-size: 15px;
    font-weight: 500;
}

.react-calendar__viewContainer {
    margin-top: 17px;
}

.react-calendar__month-view__weekdays__weekday {
    color: #c4c4c4;
}

.react-calendar__month-view__days__day {
    padding: 2px;
}

.react-calendar__tile--active {
    background: #e091a9;
    color: white;
}

.popup_inner_calendar {
    width: 280px;
    /* height: 310px; */
    position: absolute;
    top: 50%;
    left: 50%;
    margin-right: -50%;
    transform: translate(-50%, -50%);
    margin: auto;
    background: white;
    border-radius: 10px;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    padding-bottom: 5px;
}

.popup_inner_header {
    margin-top: 15px;
    margin-bottom: 10px;
    text-align: center;
}

.popup_inner_header button {
    width: 46px;
    height: 27px;
    border-radius: 3px;
    background-color: #f7f7f7;
}

.popup_inner_header button:last-child {
    margin-left: 8px;
}

.popup_inner_header button span {
    width: 28px;
    /* height: 15px; */
    line-height: 26px;
    /* font-family: NotoSansCJKKR; */
    font-size: .6875rem;
    font-weight: 500;
    text-align: center;
    color: #515151;
}

.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
    background: #c08399;
    color: #515151;
}

.selectedDate {
    padding: 5px 0;
    background: #f7f7f7;
    color: #515151;
    text-align: center;
    font-size: .6875rem;
}

.popup_cal_inner_bottom {
    border-top: 1px dotted #c4c4c4;
    padding: 10px 0;
}

.popup_cal_inner_bottom button svg {
    vertical-align: baseline;
}


/* Calendar Button */

.calendarBtn {
    width: 46px;
    height: 27px;
    border-radius: 3px;
    background-color: #f7f7f7;
    font-size: 20px;
    line-height: 27px
}

.calendarBtn:first-child {
    margin-right: 5px;
    background: #f42390;
    color: white;
}

.calendarBtn:last-child {
    margin-right: 5px;
    color: #515151;
    background: #ffe2ed;
}

button:disabled,
button[disabled] {
    border: 1px solid #999999 !important;
    background-color: #cccccc !important;
    color: #666666 !important;
}

.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
    padding: .5em !important;
    font-size: .875rem;
}

.react-calendar__century-view__decades__decade {
    font-size: .5rem !important;
}