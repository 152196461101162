button {
    border: 0;
    cursor: pointer;
    background-color: lightgray;
}

button:hover {
    background-color: lightgray;
}

button:focus,
button:active {
    outline: none;
}

button::-moz-focus-inner {
    border: 0;
}

.pin {
    background-color: white;
    border-radius: 98px;
    width: 16px;
    height: 16px;
    position: absolute;
    top: 3px;
    left: 3px;
    transition: left ease .5s;
}

button.on {
    background-color: #D01A6F;
}

button.on .pin {
    left: 35px;
}

.button-on {
    position: absolute;
    top: 4px;
    left: 8px;
}

.button-off {
    position: absolute;
    top: 4px;
    right: 6px;
}

.button-on,
.button-off {
    color: white;
    font-weight: 600;
    font-size: 13px;
    font-family:'Pretendard';
    line-height: 15px;
}